import { Controller } from 'stimulus';
import HillChart from 'helpers/hill-chart';

const COLORS = ['coral', 'crimson', 'cornflowerblue', 'blueviolet', 'aquamarine', 'darkorchid', 'dodgerblue', 'hotpink', 'gold', 'gray'];

export default class extends Controller {
  static get targets() {
    return ['input', 'chart'];
  }

  connect() {
    const hillchartData = localStorage.getItem('hillChartData');
    this.chartData = [];
    if (hillchartData) {
      this.chartData = JSON.parse(hillchartData);
    }

    this.hillChart = new HillChart(this.chartData, {
      target: this.chartTarget,
      width: 700,
      height: 270,
      darkMode: false,
      backgroundColor: 'transparent'
    });
    this.hillChart.on('moved', this.handleMoved);
    this.hillChart.render();
  }

  disconnect() {
    this.hillChart.off('moved', this.handleMoved);
    this.hillChart = null;
  }

  reset = (event) => {
    event.preventDefault();
    this.chartData = [];
    this.save();
    this.refreshAndRender();
  }

  add = (event) => {
    event.preventDefault();
    const newPointText = this.inputTarget.value;
    this.inputTarget.value = '';

    if (newPointText) {
      const color = COLORS[Math.floor(Math.random() * COLORS.length)];
      this.chartData = [...this.chartData, { color, x: 0.0, y: 0.0, description: newPointText }]
      this.save();
      this.refreshAndRender();
    }
  }

  handleMoved = (movedData) => {
    this.chartData = this.chartData.reduce((newData, point) => {
      if (!point.id || movedData.id === point.id) {
        newData.push({ ...movedData, y: point.y }); // This is a hack to work around a bug in the hill chart library
      } else {
        newData.push(point)
      }

      return newData;
    }, []);

    this.save();
  }

  refreshAndRender = () => {
    this.hillChart.replaceAndUpdate(this.chartData);
  }

  save = () => {
    if (this.chartData.length === 0) {
      localStorage.removeItem('hillChartData');
    } else {
      localStorage.setItem('hillChartData', JSON.stringify(this.chartData));
    }
  }
}
